import React from 'react'
import classNames from 'classnames'


const SegmentTabsWrapper = (props) => {
    const classList = classNames('', props.className)
    return <div className={classList}>{props.children}</div>
}

export default SegmentTabsWrapper
