import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { ListItem } from "@lib/Exp/List";

import Subtract from "./icons/subtract";
import Add from "./icons/add";
import { returnFareName } from "@ui/constants/fareType";
import { useAppDispatch } from "@redux/hooks"
import { defaultBlue } from "@utils/designSystem";
import { buildVariant } from "@root/configs/Whitelabel";

const skipArray = ["student", "senior_citizen"];
class Count extends PureComponent {
  _decrementValue = () => {
    this.props.onDecrement(this.props.id, this.props.minValue);
  };

  _incrementValue = () => {
    const disableBlock = skipArray.includes(this.props.activeFare);
    if (disableBlock && this.props.id != "adults") {
      let fareType = returnFareName(this.props.activeFare);
      this.props.showToast(
        `You cannot add ${this.props.id} to ${fareType}`
        // showCloseButton: false,
        // containerClass: "bottom-toast",
      )
    } else {
      this.props.onIncrement(this.props.id);
    }
  };

  _getClassNames = (flag) => {
    return classNames({
      Counter__disabled: flag,
    });
  };

  _getSubtractElem = (flag) => {
    if (!(this.props.simpleView && flag))
      return (
        <Subtract
          className={this._getClassNames(flag)}
          color={defaultBlue[buildVariant]}
          onClick={this._decrementValue}
        />
      );
  };

  _getAddElem = (flag) => {
    return (
      <Add
        className={this._getClassNames(flag)}
        color={defaultBlue[buildVariant]}
        onClick={this._incrementValue}
        data-testid="addSvg"
      />
    );
  };

  _getCountValue = () => {
    const { simpleView, value } = this.props;
    if ((simpleView && value > 0) || !simpleView) {
      return <p className="Counter__count w-56">{this.props.value}</p>;
    }
  };

  render() {
    const { totalValue, maxValue, minValue, value } = this.props;
    const disableIncrement = totalValue === maxValue;
    let disableDecrement = totalValue === minValue || value === minValue;
    const disableBlock = skipArray.includes(this.props.activeFare);
    if (disableBlock && this.props.id != "adults") disableDecrement = true;
    return (
      <ListItem
        className={classNames(
          "Counter__item h-64 flex flex-middle flex-between",
          { Counter__disabled: disableBlock && this.props.id != "adults" }
        )}
      >
        <div className="Counter__title">
          {this.props.children(this.props.value)}
        </div>
        <div className="Counter__actions">
          {this._getSubtractElem(disableDecrement)}
          {this._getCountValue()}
          {this._getAddElem(disableIncrement)}
        </div>
      </ListItem>
    );
  }
}

Count.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.func,
  /**
   * @ignore - internal prop
   */
  value: PropTypes.number,
  /**
   * @ignore - internal prop
   */
  totalValue: PropTypes.number,
  /**
   * @ignore - internal prop
   */
  maxValue: PropTypes.number,
  /**
   * @ignore - internal prop
   */
  onIncrement: PropTypes.func, // (id)
  /**
   * @ignore - internal prop
   */
  onDecrement: PropTypes.func, // (id)
  minValue: PropTypes.number,
};

Count.defaultProps = {
  id: "",
  className: "",
  value: 0,
  children: () => {},
  totalValue: 0,
  minValue: 0,
};

export default Count;
