import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { List, ListItem } from 'Lib/List'
import Button from 'Lib/Buttons'
import InputBlock from 'Lib/Form/InputBlock'
import InputBlockNew from 'Lib/Exp/Form/InputBlock'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { getAmendmentData } from '@redux/manager/amendmentManager'
import { Shake } from 'Lib/Animate'
import { dateType } from '@ui/constants/types'
import { FLIGHT_CLASS } from '@ui/constants'
import {
    path,
    isEmpty,
    fromQueryString,
    convertDate,
    formatDate,
    getFlightsTravellersText,
    invokeCustomEvent,
    getCookie
} from '@ui/utils'
import { SegmentTab, SegmentTabBar } from '@components/SegmentTabBar'
import { dataLayerFlightPageValues } from '@utils/flights/CommonAnalytics'
import { dataLayerGA } from '@utils/datalayer'
import dayjs from 'dayjs'
import Analytics from '@ui/utils/Analytics'
import { setQueryObj } from '@redux/manager/ui'
import { fetchMerchandisingData } from '@redux/manager/merchandisingManager'

function Search(props) {
    const mevcVariant = 'v1'
    const amendmentData = useAppSelector(getAmendmentData)
    let isAmendment = amendmentData.isAmendment
    const dispatch = useAppDispatch()
    const [state, setState] = useState({
        animate: {
            origin: false,
            destination: false,
            depart: false,
            travellers: false,
            returnOn: false
        },
        error: {
            origin: false,
            destination: false,
            depart: false,
            travellers: false,
            returnOn: false
        },
        activeFare: 'rf',
        flightClass: FLIGHT_CLASS,
        showClassDropDown: false,
        nonStopFilter: false
    })

    const [loginStatus, setLoginStatus] = useState(false)
    const [showCfw, setShowCfw] = useState(false)

    const timerRef = useRef(null)

    const spoofMessages = [
        { description: 'New attractive fares with great savings' },
        { description: 'Free seats & complimentary meals' },
        { description: 'Low cancellation & date change charges' },
        { description: 'Price comparison now easier than ever' }
    ]

    useEffect(() => {
        if (props.sft && state.activeFare !== props.sft) {
            state.activeFare = props.sft
        }
        dispatch(
            fetchMerchandisingData({
                international: true,
                arrivalDate: props.tripType === 'oneway' ? '' : props.returnOn,
                ssr: false
            })
        )
    }, [])

    useEffect(() => {
        if (state.activeFare !== props.activeFare) state.activeFare = props.activeFare
    }, [props.activeFare])

    useEffect(() => {
        if (loginStatus) {
            validateData()
        }
    }, [loginStatus])

    useEffect(() => {
        const currOriginAirport = props?.origin?.airport || ''
        const currDestinationAirport = props?.destination?.airport || ''
        if ((props.isIntlArray[0] || props.isIntlArray[1]) && props.cfw) {
            clearTimeout(timerRef.current)
            // Adding setTimeout to overwrite the delay between popstate of the subsequent bottomsheets
            timerRef.current = setTimeout(() => {
                props.setCfw(false)
                setCfwErrorModal('Cleartrip for work can only be accessed for domestic flights')
                setCfwErrorModalButtonText('Okay, got it')
            }, 100)
        }
        if (!isEmpty(currOriginAirport) && !isEmpty(currDestinationAirport)) {
            const originAirport = currOriginAirport.split(',')
            const destinationAirport = currDestinationAirport.split(',')
            filterFlightClass(originAirport, destinationAirport)
        }

        return () => {
            clearTimeout(timerRef.current)
        }
    }, [props.origin, props.destination])

    useEffect(() => {
        dataLayerFlightPageValues()

        const payload = {
            section: 'Flights'
        }
        if (props.fromSRP) {
            if (
                (props.queryParams?.isMobileApp == 'true' &&
                    (props.queryParams?.deviceType?.toLowerCase() === 'ios' ||
                        props.queryParams?.deviceType?.toLowerCase() === 'iphoneapp')) ||
                (typeof window.sessionStorage != 'undefined' && sessionStorage.getItem('mobileApp') === 'iPhoneApp')
            ) {
                setTimeout(() => {
                    dataLayerGA(payload, 'home_page_land')
                }, 5000)
            } else {
                dataLayerGA(payload, 'home_page_land')
            }
        }
    }, [])

    function filterFlightClass(originAirport, destinationAirport) {
        const isOriginDomestic = originAirport[1] && originAirport[1].indexOf('IN') !== -1
        const isDestinationDomestic = destinationAirport[1] && destinationAirport[1].indexOf('IN') !== -1
        const isDomestic = isOriginDomestic && isDestinationDomestic
        if (isDomestic) {
            const flightClass = state.flightClass.filter((fClass, index) => fClass.code !== 'First')
            setState({ ...state, flightClass })
        } else {
            setState({ ...state, flightClass: FLIGHT_CLASS })
        }
    }

    function formatDateHandler(date) {
        if (!isEmpty(date)) {
            return formatDate(date, props.t)
        }
        return ''
    }

    function isSearchDataSame() {
        const search = path(['search'], window.location) || ''
        const params = search.slice(1)
        const data = fromQueryString(params)
        if (!data.sft) data.sft = 'rf'

        const { origin, destination, depart, travellers, returnOn, class: fClass, activeFare, cfw, isCfw } = props
        let { adults, children, infants } = travellers

        if (data.from !== origin.code) {
            return false
        } else if (data.to !== destination.code) {
            return false
        } else if (dayjs(depart).format('DD/MM/YYYY') !== data.depart_date) {
            return false
        } else if (data.class !== fClass) {
            return false
        } else if (parseInt(data.adults) !== adults) {
            return false
        } else if (parseInt(data.childs) !== children) {
            return false
        } else if (parseInt(data.infants) !== infants) {
            return false
        } else if (
            returnOn &&
            props.tripType === 'roundtrip' &&
            data.return_date !== dayjs(returnOn).format('DD/MM/YYYY')
        ) {
            return false
        } else if (!data.return_date && returnOn && props.tripType === 'roundtrip') {
            return false
        } else if (data.return_date && props.tripType === 'oneway') {
            return false
        } else if (activeFare !== data.sft) {
            return false
        } else if (cfw !== isCfw) {
            return false
        }
        return true
    }

    function isDomestic() {
        const { origin, destination } = props
        const isOriginDomestic = origin?.airport?.split(',')[1]?.split(' ').includes('IN')
        const isDestinationDomestic = destination?.airport?.split(',')[1]?.split(' ').includes('IN')
        const isDomestic = isOriginDomestic && isDestinationDomestic
        return isDomestic
    }
    /* eslint complexity: [ 0 ] */
    function validateData(event) {
        // import(
        //   /* webpackPreload: true */ /* webpackChunkName: "FlightsResults" */ "pages/Flights/Results"
        // );
        const search = path(['search'], window.location) || ''
        const params = search.slice(1)
        const data = fromQueryString(params)

        const {
            origin,
            destination,
            depart,
            travellers,
            returnOn,
            class: fClass,
            splFareType,
            fromSRP,
            setIsLoginOpen,
            cfw,
            isLoginOpen,
            tripType,
            activeFare,
            isIntlArray
        } = props

        dispatch(
            setQueryObj({
                origin,
                destination,
                depart,
                travellers,
                returnOn,
                tripType,
                fClass,
                activeFare,
                fromHomePage: !fromSRP,
                isIntl: isIntlArray[0] || isIntlArray[1]
            })
        )

        const payload = {
            origin: origin?.code,
            destination: destination?.code,
            journey_start_date: convertDate(depart, '/'),
            journey_return_date: props?.tripType !== 'oneway' ? convertDate(returnOn, '/') : 'N/A',
            Adult_Count: travellers?.adults,
            Child_Count: travellers?.children,
            trip_type: props?.tripType === 'oneway' ? 'single' : 'round',
            section: 'Flights',
            flight_type: isDomestic() ? 'Domestic' : 'International'
        }
        dataLayerGA(payload, 'flight_search_click')
        Analytics.event('Air_UI_Action', {
            action_name: 'modify_search',
            ...props.instrumentationPayload
        })
        const obj = { animate: {}, error: {} }
        const currDate = new Date()
        let isToastVisible = false
        const isOldDate = time => {
            currDate.setHours(0, 0, 0, 0)
            return time < currDate.getTime()
        }
        if (isEmpty(origin.code)) {
            obj.animate.origin = true
            obj.error.origin = true
            if (!isToastVisible) {
                isToastVisible = true
                props.handleValidationError('Please select location for departure')
            }
        }
        if (isEmpty(destination.code)) {
            obj.animate.destination = true
            obj.error.destination = true
            if (!isToastVisible) {
                isToastVisible = true
                props.handleValidationError('Please select location for arrival')
            }
        }
        if (origin.code === destination.code) {
            obj.animate.origin = true
            obj.error.origin = true
            obj.animate.destination = true
            obj.error.destination = true
            if (!isToastVisible) {
                isToastVisible = true
                props.handleValidationError('Your origin and destination cities are the same')
            }
        }
        if (isEmpty(depart) || isOldDate(depart)) {
            obj.animate.depart = true
            obj.error.depart = true
            if (!isToastVisible) {
                isToastVisible = true
                props.handleValidationError('Please select valid depart date')
            }
        }

        if (props.tripType === 'roundtrip') {
            if (
                isEmpty(returnOn) ||
                isOldDate(depart) ||
                (!isEmpty(returnOn) && !isEmpty(depart) && parseInt(depart, 10) > parseInt(returnOn, 10))
            ) {
                obj.animate.returnOn = true
                obj.error.returnOn = true
                if (!isToastVisible) {
                    props.handleValidationError('Please select valid return date')
                }
            }
        }
        if (isEmpty(travellers) || !(travellers.adults + travellers.children + travellers.infants)) {
            obj.animate.travellers = true
            obj.error.travellers = true
            if (!isToastVisible) {
                isToastVisible = true
                props.handleValidationError('Please select travellers')
            }
        }
        if (!isEmpty(obj.animate)) {
            setState({ ...state, ...obj })
            setTimeout(() => {
                setState({
                    ...state,
                    animate: {
                        origin: false,
                        destination: false,
                        depart: false,
                        travellers: false,
                        returnOn: false
                    }
                })
            }, 500)
        } else {
            if (isSearchDataSame() && props.fromSRP) {
                props.closeFromSRP && props.closeFromSRP()
            } else {
                props.handleFlightSearch()
                props.closeFromSRP && props.closeFromSRP()
            }
        }
    }

    function handleOriginAirportSearch() {
        if (state.error.origin) {
            setState({
                ...state,
                error: { ...state.error, origin: !state.error.origin }
            })
        }
        props.showOriginSearch()
    }

    function handleDestinationAirportSearch() {
        if (state.error.destination) {
            setState({
                ...state,
                error: {
                    ...state.error,
                    destination: !state.error.destination
                }
            })
        }
        props.showDestinationSearch()
    }

    function handleTravellersClick() {
        if (state.error.travellers) {
            setState({
                ...state,
                error: { ...state.error, travellers: !state.error.travellers }
            })
        }
        props.showTravellerSelect()
    }

    function handleClassChange(val) {
        setState({ ...state, showClassDropDown: false })
        props.handleClassChange(val)
        Analytics.event('Air_UI_Action', {
            action_name: 'class updated',
            travel_class: val
        })
    }
    const SwapIcon = ({ onClick = () => {}, stroke = '#36c', isAmendment = false }) => {
        return (
            <svg
                onClick={isAmendment ? () => {} : onClick}
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                fill="none"
                viewBox="0 0 48 48"
            >
                <path
                    stroke={stroke}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.25"
                    d="M33.333 22.667H14.667M14.667 22.667L21.333 16M14.667 26.667h18.666M33.333 26.667l-6.666 6.666"
                ></path>
                <circle
                    cx="24"
                    cy="24"
                    r="15.375"
                    stroke={stroke}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.25"
                ></circle>
            </svg>
        )
    }
    const renderDateField = () => {
        if (props.tripType === 'oneway') {
            return (
                <div className="TouchAndGo__group">
                    <InputBlock
                        label={'Departure'}
                        fieldClass={`TouchAndGo__tappable--left flex-1_75 `}
                        labelClass="fs-12 bento-c-neutral-500 line_height_16 fw-400 margin_bottom_4"
                        valueClass="fs-16 c-black-80 fw-500 line_height_28"
                        value={formatDateHandler(props.depart)}
                        onClick={props.handleSingleDepartClick}
                        hasError={state.error.depart}
                    />
                    {props.tripType == 'roundtrip' && (
                        <InputBlock
                            label={'Return'}
                            fieldClass={`TouchAndGo__tappable--right flex-2 ${
                                isAmendment ? 'bg-neutral-300 disable' : ''
                            }`}
                            labelClass="fs-12 bento-c-neutral-500 line_height_16 fw-400 margin_bottom_4"
                            valueClass="fs-16 color-neutral-999 fw-500 line_height_28"
                            value={'Add for discounts'}
                            onClick={() => {
                                props.handleClickFlag()
                                if (mevcVariant === 'v2') {
                                    props.handleReturnClick()
                                }
                            }}
                            hasError={state.error.returnOn}
                        />
                    )}
                </div>
            )
        }
        if (mevcVariant === 'v2') {
            return (
                <div>
                    <div className="TouchAndGo__group">
                        <InputBlock
                            label={'Departure'}
                            fieldClass={`TouchAndGo__tappable--left `}
                            labelClass="fs-12 bento-c-neutral-500 line_height_16 fw-400 margin_bottom_4"
                            valueClass="fs-16 c-black-80 fw-500 line_height_28"
                            value={formatDateHandler(props.depart)}
                            onClick={props.handleSingleDepartClick}
                            hasError={state.error.depart}
                        />

                        {props.returnOn ? (
                            <InputBlockNew
                                label={'Return'}
                                fieldClass={`TouchAndGo__tappable--right `}
                                labelClass="fs-12 bento-c-neutral-500 line_height_16 fw-400 margin_bottom_4"
                                valueClass="fs-16 c-black-80 fw-500 line_height_28"
                                value={formatDateHandler(props.returnOn)}
                                onClick={props.handleReturnClick}
                                hasError={state.error.returnOn}
                                returnBackToOneWay={() => props.handleTripTypeChange('oneway')}
                                returnFromOw={isAmendment ? false : true}
                            />
                        ) : (
                            <InputBlock
                                label={'Return'}
                                fieldClass="TouchAndGo__tappable--right flex-2"
                                labelClass="fs-12 bento-c-neutral-500 line_height_16 fw-400 margin_bottom_4"
                                valueClass="fs-16 color-neutral-999 fw-500 line_height_28"
                                value={'Add for discounts'}
                                onClick={props.handleReturnClick}
                                hasError={state.error.returnOn}
                            />
                        )}
                    </div>
                </div>
            )
        }
        return (
            <div>
                <div className="TouchAndGo__group">
                    <InputBlock
                        label={'Departure'}
                        fieldClass={`TouchAndGo__tappable--left `}
                        labelClass="fs-14 margin_bottom_4 bento-c-neutral-500 line_height_20"
                        valueClass="fs-16 c-black-80 fw-500 line_height_28"
                        value={formatDateHandler(props.depart)}
                        onClick={props.handleDepartClick}
                        hasError={state.error.depart}
                    />
                    <InputBlockNew
                        label={'Return'}
                        fieldClass={`TouchAndGo__tappable--right `}
                        labelClass="fs-14 margin_bottom_4 bento-c-neutral-500 line_height_20"
                        valueClass="fs-16 c-black-80 fw-500 line_height_28"
                        value={formatDateHandler(props.returnOn)}
                        onClick={props.handleReturnClick}
                        hasError={state.error.returnOn}
                        returnBackToOneWay={() => props.handleTripTypeChange('oneway')}
                        returnFromOw={isAmendment ? false : true}
                    />
                </div>
            </div>
        )
    }

    let originAirport = path(['origin', 'airport'], props) ? props.origin.airport.split(',') : []
    let destinationAirport = path(['destination', 'airport'], props) ? props.destination.airport.split(',') : []
    originAirport = path([0], originAirport)
    destinationAirport = path([0], destinationAirport)

    const originLabel = `From - ${props.origin.code}`
    const destinationLabel = `To - ${props.destination.code}`
    const travellerFieldTitle = 'Travellers & Class'

    return (
        <div>
            <div>
                {props.title && 
                    <div className='lh-30' style={{paddingBottom:"20px"}}>
                        <h1>{props.title}</h1>
                        <span class="ct-tagline" style={{fontSize:"16px",color:"#999"}}>Fly anywhere. Fly everywhere</span>
                        <br/>
                    </div>
                }
                
                <SegmentTabBar selectedValue={props.tripType} onChange={props.handleTripTypeChange}>
                    <SegmentTab
                        label={'One way'}
                        value="oneway"
                        fromHP={true}
                        renderNewCalendarFlow={props?.renderNewCalendarFlow}
                    />
                    <SegmentTab
                        label={'Round trip'}
                        value="roundtrip"
                        fromHP={true}
                        renderNewCalendarFlow={props?.renderNewCalendarFlow}
                    />
                </SegmentTabBar>
            </div>

            <div className="TouchAndGo p-relative pt-18">
                <List>
                    {(props.tripType === 'oneway' || props.tripType === 'roundtrip') && (
                        <ListItem className={`px-0 pt-16 ${originAirport && destinationAirport ? 'pb-12' : ''}`}>
                            <Shake animate={state.animate.origin || state.animate.destination}>
                                <div className={`TouchAndGo__group ${isAmendment ? 'bg-neutral-300' : ''}`}>
                                    <InputBlockNew
                                        label={props?.origin?.code ? originLabel : 'From'}
                                        fieldClass={`TouchAndGo__tappable--large-left ${
                                            isAmendment ? 'bg-neutral-300 disable' : ''
                                        }`}
                                        labelClass={
                                            props.origin.code
                                                ? `fs-12 bento-c-neutral-500 line_height_16 fw-400 ${
                                                      !props.fromSRP ? 'mb-4' : 'margin_bottom_4'
                                                  }`
                                                : 'fs-12 bento-c-neutral-500 line_height_16'
                                        }
                                        value={originAirport}
                                        valueClass="fs-20 fw-500 line_height_28"
                                        onClick={handleOriginAirportSearch}
                                        showEmptyValue={true}
                                        hasError={state.error.origin}
                                        originDestinationFlag="origin"
                                    />
                                    <SwapIcon isAmendment={isAmendment} onClick={props.swapAirports} stroke="#36C" />
                                    <InputBlockNew
                                        label={props?.destination?.code ? destinationLabel : 'To'}
                                        fieldClass={`TouchAndGo__tappable--large-right ${
                                            isAmendment ? 'bg-neutral-300 disable' : ''
                                        }`}
                                        labelClass={
                                            props.destination.code
                                                ? `fs-12 bento-c-neutral-500 line_height_16 fw-400 ${
                                                      !props.fromSRP ? 'mb-4' : 'margin_bottom_4'
                                                  }`
                                                : 'fs-12 bento-c-neutral-500 line_height_16'
                                        }
                                        value={destinationAirport}
                                        valueClass="fs-20 fw-500 line_height_28"
                                        onClick={handleDestinationAirportSearch}
                                        showEmptyValue={true}
                                        hasError={state.error.destination}
                                        originDestinationFlag="destination"
                                    />
                                </div>
                            </Shake>
                        </ListItem>
                    )}

                    {(props.tripType === 'oneway' || props.tripType === 'roundtrip') && (
                        <ListItem className="px-0 py-12">
                            <Shake animate={state.animate.depart || state.animate.returnOn}>{renderDateField()}</Shake>
                        </ListItem>
                    )}

                    <ListItem
                        onClick={isAmendment ? () => {} : handleTravellersClick}
                        enableRipple={true}
                        gh39Flag={true}
                        className="px-0 Datalist__item py-12"
                    >
                        <Shake animate={state.animate.travellers}>
                            <div className={`TouchAndGo__group  `}>
                                <InputBlock
                                    label={`${travellerFieldTitle}`}
                                    fieldClass={`TouchAndGo__tappable--row ${
                                        isAmendment ? 'bg-neutral-300 disable' : ''
                                    }`}
                                    labelClass="fs-12 bento-c-neutral-500 line_height_16 fw-400 margin_bottom_4"
                                    valueClass="fs-16 c-black-80 fw-500 line_height_28"
                                    value={`${getFlightsTravellersText(props.travellers)}${`, ${props.class}`} `}
                                    hasError={state.error.travellers}
                                />
                            </div>
                        </Shake>
                    </ListItem>

                    <ListItem className="bb-0 px-0 pt-18">
                        <Button className="br-8" onClick={validateData}>
                            {'Search flights'}
                        </Button>
                    </ListItem>
                </List>
            </div>
        </div>
    )
}

Search.propTypes = {
    class: PropTypes.string,
    queryParams: PropTypes.string,
    depart: PropTypes.oneOfType([PropTypes.string, PropTypes.number, dateType]),
    returnOn: PropTypes.oneOfType([PropTypes.string, PropTypes.number, dateType]),
    origin: PropTypes.shape({
        code: PropTypes.string,
        airport: PropTypes.string
    }),
    showOriginSearch: PropTypes.func,
    showDestinationSearch: PropTypes.func,
    showTravellerSelect: PropTypes.func,
    destination: PropTypes.shape({
        code: PropTypes.string,
        airport: PropTypes.string
    }),
    travellers: PropTypes.shape({
        adult: PropTypes.number,
        children: PropTypes.number,
        infants: PropTypes.number
    }),
    handleClassChange: PropTypes.func,
    handleFlightSearch: PropTypes.func,
    swapAirports: PropTypes.func,
    handleTripTypeChange: PropTypes.func,
    tripType: PropTypes.string,
    handleDepartClick: PropTypes.func,
    handleReturnClick: PropTypes.func,
    handleValidationError: PropTypes.func,
    handleSingleDepartClick: PropTypes.func
}

Search.defaultProps = {
    class: 'Economy',
    depart: '...',
    returnOn: '...',
    origin: {
        code: '',
        airport: 'From'
    },
    queryParams: '',
    showOriginSearch: event => {},
    showDestinationSearch: event => {},
    showTravellerSelect: event => {},
    destination: {
        code: '',
        airport: 'To'
    },
    travellers: {
        adult: 0,
        children: 0,
        infants: 0
    },
    handleClassChange: value => {},
    handleFlightSearch: () => {},
    swapAirports: event => {},
    handleTripTypeChange: () => {},
    tripType: 'oneway',
    handleDepartClick: () => {},
    handleReturnClick: () => {},
    handleValidationError: () => {},
    handleSingleDepartClick: () => {}
}

export default Search
