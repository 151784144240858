import React, { useState, useEffect } from "react";
import { apiGetHelper } from "src/utils/helper";

import logError from "@utils/logError";
import PropTypes from "prop-types";
import Autocomplete from "@lib/Autocomplete";
import { List } from "@lib/List";
import { getApiDomain } from "@utils/helper";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  resetSearch,
  getAirportSearchResult,
  searchSuccess,
  searchFailure,
} from "@redux/manager/searchManager";

function Search(props) {
  const [state, setState] = useState({
    value: "",
    isAutoDetect: false,
  });

  const dispatch = useAppDispatch();
  let data = useAppSelector(getAirportSearchResult);

  useEffect(() => {
    return () => {
      if (data.result.length > 0) {
        dispatch(resetSearch());
      }
    };
  }, []);

  async function fetchFlightAirports(query, meta) {
    let DOMAIN = getApiDomain();
    let URL = `/places/airports/search?string=${query}`;

    try {
      const locations = await apiGetHelper(URL);
      let { data = [] } = locations || {}
      data = data.map((loc) => {
        const airportData = loc.v.split("(");
        const code = loc.k;
        const v = airportData[1] ? airportData[0].slice(0, -1) : airportData[0];
        return {
          ...loc,
          ...{ code, airport: v, isAutoDetect: meta.isAutoDetect },
        };
      });
      dispatch(searchSuccess({ data, query }));
    } catch (e) {
      logError(e);
      dispatch(searchFailure({ e, query }));
    }
  }
  function handleInput(value, inputName, event) {
    setState({ ...state, value });

    if (value.length > 2) {
      fetchFlightAirports(value, props.meta);
    } else if (data.result.length > 0) {
      dispatch(resetSearch());
    }
  }

  function onClose() {
    setState({ ...state, value: "" });
    props.onClose();
  }

  function onSelect(item, selectedDataType) {
    setState({ ...state, value: "" });
    props.onSelect(item, selectedDataType);
  }

  return (
    <Autocomplete
      isOpen={props.isOpen}
      title={props.title}
      onClose={onClose}
      hashUrl="airportSearchModal"
      autoFocus={true}
      focusDelay={300}
      placeholder={props.placeholder}
      value={state.value}
      data={data.result}
      onSelect={onSelect}
      onChange={handleInput}
      renderItem={props.renderItem}
      renderMenu={props.renderMenu}
      popularData={props.popularData}
      recentData={props.recentData}
      recentSearchesHeader={props.meta.recentSearchesHeader}
      popularHeader={props.meta.popularHeader}
      enableGeoLocation={props.enableGeoLocation}
      hotelsSearchCategories={props.hotelSearchCategories}
      showSearchCategory={props.meta.showSearchCategory}
      showGeoLocationLoader={state.isAutoDetect}
    />
  );
}

Search.propTypes = {
  /**
   * The popular items to display in the dropdown menu
   */
  popularData: PropTypes.arrayOf(PropTypes.object),
  /**
   * The recently searched items to display in the dropdown menu
   */
  recentData: PropTypes.arrayOf(PropTypes.object),
  /**
   * Arguments: `value: String, item: Any`
   *
   * Invoked when the user selects an item from the dropdown menu.
   */
  onSelect: PropTypes.func,
  /**
   * Arguments: `item: Any`
   *
   * Invoked for each entry in `items` that also passes `shouldItemRender` to
   * generate the render tree for each item in the dropdown menu.
   */
  renderItem: PropTypes.func.isRequired,
  /**
   * Arguments: `items: Array<Any>, value: String,`
   *
   * Invoked to generate the render tree for the dropdown menu. Ensure the
   * returned tree includes `items` or else no items will be rendered.
   */
  renderMenu: PropTypes.func,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    product: PropTypes.oneOf(["trains", "flights", "hotels", "locals"]),
    type: PropTypes.string,
    cityType: PropTypes.string,
    popularHeader: PropTypes.string,
    recentSearchesHeader: PropTypes.string,
    showSearchCategory: PropTypes.bool,
  }),
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

Search.defaultProps = {
  onSelect: null,
  renderMenu: (items, value) => {
    return <List>{items}</List>;
  },
  placeholder: "",
  meta: {},
  popularData: [],
  recentData: [],
};

export default Search;
