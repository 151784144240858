import React from 'react'

function Icon({ color = '#36c', className = '', ...rest }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="33"
            viewBox="0 0 24 24"
            className={className}
            {...rest}
        >
            <g fill="none" fillRule="evenodd" stroke={color} strokeWidth="0.7">
                <circle cx="12" cy="12" r="11" stroke={color}></circle>
                <path d="M6 12h12"></path>
            </g>
        </svg>
    )
}

export default Icon
