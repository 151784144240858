import { createContext, useContext } from 'react';

type FunctionContextType = {
  startTransition: (cb: () => void, shimmerName: string) => void;
  isTransitioning: boolean; // Add state to track transition status
};

export const GlobalAppContext = createContext<FunctionContextType>(() => {});

export function useNavigationContext() {
  return useContext(GlobalAppContext);
}
