import React, { Children, isValidElement, cloneElement, createElement } from 'react'
import classNames from 'classnames'

import SegmentTabsWrapper from './SegmentTabsWrapper'
import { isFkEnv } from '@root/configs/Whitelabel'


const SegmentTabBar = props => {
    const getTabs = () => {
        const tabs = []
        Children.forEach(props.children, tab => {
            if (isValidElement(tab)) {
                tabs.push(tab)
            }
        })
        return tabs
    }

    const checkSelectedTab = tab => {
        if (props.selectedValue) {
            return props.selectedValue === tab.props.value
        } else return false
    }

    const handleTabTouchTap = (value, event, tab) => {
        if (props.alwaysTriggerOnChange || props.value !== value) {
            props.onChange(value, event, tab)
        }
        // safeInvoke(tab.props.onActive, tab)
    }

    const tabContent = []
    const Wrapper = props.tabWrapper || SegmentTabsWrapper
    const tabs = getTabs().map(tab => {
        let child
        const isSelectedTab = checkSelectedTab(tab)
        if (isSelectedTab && tab.props.children) {
            child = createElement(
                Wrapper,
                {
                    key: tab.props.value,
                    selected: isSelectedTab,
                    className: contentContainerClassName
                },
                tab.props.children
            )
        } else {
            child = undefined
        }
        tabContent.push(child)

        return cloneElement(tab, {
            key: tab.props.value,
            selected: isSelectedTab,
            onClick: handleTabTouchTap
        })
    })

    const classList = classNames('SegmentTabs', props.className)
    return (
        <div className={classList}>
            <div className={`SegmentTabs__${isFkEnv? 'fk-' : '' }nav`}>{tabs}</div>
        </div>
    )
}

export default SegmentTabBar
