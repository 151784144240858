import logError from "@utils/flights/logError";
import { utmGetUserPrefCurrency, readCookie } from "@utils/flights";
import { readParam, getLanguageMeta } from "@ui/utils";
import { HOST } from "@ui/constants";
export function getClevertapBasicProperties() {
  const isLoggedIn = !!readCookie();
  const networkType =
    navigator && navigator.connection && navigator.connection.effectiveType;
  return {
    source: "mobile",
    is_logged_in: isLoggedIn ? "yes" : "no",
    domain: window.location.host,
    network_type: networkType,
    product: "AIR",
    language: "en",
    isPWALite:
      window && window.sessionStorage && window.sessionStorage.isLiteApp
        ? "yes"
        : "no",
    user_pref_currency: utmGetUserPrefCurrency(),
    isAmendment:window.location.pathname == '/flights/amendments/search-results'?true:false
  };
}

export function dataLayerFlightPageValues() {
  try {
    const { country } = getLanguageMeta();
    readParam("utm_source", { expiry: 30 });
    readParam("utm_campaign", { expiry: 30 });
    readParam("utm_medium", { expiry: 30 });
    readParam("utm_term", { expiry: 30 });
    readParam("utm_uid", { expiry: 30 });
    const dataLayerData = {
      Product: "Air",
      Domain: HOST,
      Country: country,
      Language: "EN",
      "Device-Type": "Mobile",
      Event: "Flights_Home",
      isAmendment:window.location.pathname == '/flights/amendments/search-results'?true:false
    };
    const { dataLayer = [] } = window || {};
    dataLayer.push(dataLayerData);
  } catch (e) {
    logError(e);
    return {};
  }
}
