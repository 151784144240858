import React, { useState, useEffect, useRef, useMemo } from 'react'

import { useRouter } from 'next/router'
// import Calendar from "react-tiny-calendar";
// import CalendarNew from '@components/CalendarNew'
import dayjs from 'dayjs'
import addDays from 'date-fns/addDays'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { getAmendmentData } from '@redux/manager/amendmentManager'
import { formatDateStandardized, startOfToday } from '@utils/date/dateUtils'
import startOfMonth from 'date-fns/startOfMonth'

import { ListItem } from '@lib/List'
import Button from '@lib/Buttons'
import Sheet from '@lib/Sheet'
import { CounterNew, CountNew } from '@lib/Exp/Counter'
// import SheetNew from '@lib/Exp/Sheet'
import { CodeBadge } from '@lib/Badges'
import { Truncate } from '@lib/Text'
import { Tabs, Tab } from '@lib/Tabs'
import Analytics from '@ui/utils/Analytics'
import FlightSearchComponentNew from '@components/Search'
import AirportSearchContainer from '@components/AirportSearchContainer'
import { fareCalendarData } from '@api/homePage'

import {
    setSearchCurrentTime,
    getAirportSearchHistory,
    setAirportSearchHistory,
    saveFlightSearchQuery,
    getFlightsSearchHistorySuccess
} from '@redux/manager/searchManager'

import {
    path,
    isEmpty,
    convertDate,
    pluralize,
    getCookie,
    convertDateForTracking,
    getFlightsTravellersText,
    toQueryString
} from '@ui/utils'
import { FLIGHT_CLASS } from '@ui/constants'
import Sticky from '@lib/Sticky'
import {
    getPopularCities,
    setUtmCookieOnHomePage,
    _getDate,
    getClassNameForTracking,
    checkInternalCTUser,
    isUserSignedIn
} from '@utils/flights'
import { getClevertapBasicProperties } from '@utils/flights/CommonAnalytics'

// import { FLIGHTS_DOM_RESULTS, FLIGHTS_INTL_RESULTS } from '@constants/flights/paths'
import { MAX_ADULT_SELECTION, MIN_ADULT_SELECTION } from '@constants/flights'

import { onPageStart, sendBranchEvent } from '@utils/flights/appAgent'
import { showToast } from '@redux/manager/ui'
import { pushGAData } from '@utils/datalayer'
// import { apiGetHelper, apiPutOrPostHelper } from 'src/utils/helper'
// import { getApiDomain } from '@utils/helper'
// import ToastContainer from '@container/Toast'
import { getSearchQueryParams, getSearchURL } from '@utils/flights/search'
import { BottomSheet } from '@ct-design/lib'
import { returnFareName } from '@constants/fareType'
import { getFormattedDate } from '@root/ui/utils/dateHelper'
import { dayDiff } from '@utils/itinerary/clevertap/clevertapHelper'
import { ravenSDKTrigger } from '@utils/RavenSDKHelper'
import dynamic from 'next/dynamic'
import ItineraryLoader from '@components/ItineraryLoader'
import border from '@ct-design/token/global/border'
import { useNavigationContext } from '@components/NavigationWrapper/NavigationContext'
// import logError from '@utils/logError'
// const CalendarNew = dynamic(() => import(/* webpackChunkName: "CalendarNew" */ '@components/CalendarNew'), {
//     ssr: false,
//     loading: () => <ItineraryLoader />
// })
const Calendar = dynamic(() => import(/* webpackChunkName: "Calendar" */ 'react-tiny-calendar'), {
    ssr: false,
    loading: () => <ItineraryLoader />
})

function Search(props) {
    const { open, fromSRP = false, setShowHomeToSRPLoader = () => {}, setInitialTS = () => {} } = props
    const { locationData } = props
    const mevcVariant = 'v2'
    const mevcG2VariantNBA = 'v1'
    const renderMevcG2NBA = mevcG2VariantNBA === 'v2'

    const renderNewCalendarFlow = false
    const {
        source,
        destination: destinationQuery,
        departDate,
        flightClass,
        returnDate,
        sourceHeader,
        destinationHeader,
        sft,
        srpFlow,
        tripType,
        title,
        isCfw
    } = props.query
    const departOnInitial =
        !departDate || (departDate && new Date(_getDate(departDate)) < new Date()) ? new Date() : _getDate(departDate)

    let returnOnInitial
    if (renderNewCalendarFlow) {
        returnOnInitial = returnDate && _getDate(returnDate)
    } else {
        returnOnInitial = returnDate ? _getDate(returnDate) : addDays(departOnInitial, 0)
    }

    if (returnOnInitial < departOnInitial && !renderNewCalendarFlow) {
        returnOnInitial = departOnInitial
    }
    const amendmentData = useAppSelector(getAmendmentData)
    let isAmendment = amendmentData.isAmendment
    const [showSearchSheet, setShowSearchSheet] = useState(false)
    const [airportType, setAirportType] = useState('')
    const [showTravellerSheet, setShowTravellerSheet] = useState(false)
    const [departOn, setDepartOn] = useState(departOnInitial)
    const [travellers, setTravellers] = useState(props.query.travellers)
    const [travellerEventtrigger, setTravellerEventTrigger] = useState(false)
    const [fClass, setFClass] = useState(flightClass)
    const [clickFlag, setClickFlag] = useState(false)
    const [crossFlag, setCrossFlag] = useState(true)
    const [flightClassVal, setFlightClassVal] = useState(FLIGHT_CLASS)
    const [origin, setOrigin] = useState({ code: source, airport: sourceHeader })
    const [destination, setDestination] = useState({
        code: destinationQuery,
        airport: destinationHeader
    })
    const [returnOn, setReturnOn] = useState(returnOnInitial)
    let initialTripType
    if (renderNewCalendarFlow) {
        initialTripType = tripType ? tripType : 'roundtrip'
    } else {
        initialTripType = returnDate ? 'roundtrip' : 'oneway'
    }
    const initialTSCalendarOpen = useRef(null)
    const [state, setState] = useState({
        value: '',
        splFareType: sft ? sft : 'rf',
        tripType: initialTripType,
        showSingleDatePicker: false,
        showDateRangeCalendar: false,
        dateType: 'onward',
        showOfferDetails: false,
        showMsgToast: true
    })
    const [isIntlArray, setIsIntlArray] = useState([false, false])
    let browserHistory = useRouter()
    let today = startOfToday()
    let thisMonth = startOfMonth(today)
    let airports = useAppSelector(getAirportSearchHistory)

    let isCFWEnabled = true
    const dispatch = useAppDispatch()
    const firstLoad = useRef(true)
    const [fareCalendar, setFareCalendar] = useState({})
    const memoizedOneWayMonthsFetched = useRef([])
    const memoizedRoundTripMonthsFetched = useRef([])
    const memoizedOneWayCalendarData = useRef({})
    const memoizedRoundTripCalendarData = useRef({})
    const memoizedDepartDate = useRef({})
    const previousCalendarPayload = useRef({})
    const fareDetails = {
        cabinType: {
            ECONOMY: 'ECONOMY',
            Business: 'BUSINESS',
            First: 'FIRST',
            'Premium Economy': 'PREMIUM_ECONOMY'
        },
        fareGroup: {
            rf: 'REGULAR',
            student: 'STUDENT',
            senior_citizen: 'SENIOR_CITIZEN',
            army: 'ARMY'
        }
    }

    const [cfw, setCfw] = useState(isCfw)
    const [isLoginOpen, setIsLoginOpen] = useState(false)
    const useBt = useNavigationContext()
    useEffect(() => {
        if (!isEmpty(locationData?.origin) && !isEmpty(locationData?.destination)) {
            const { intl: originIntl = false } = locationData.origin
            const { intl: destinationIntl = false } = locationData.destination
            setIsIntlArray([originIntl, destinationIntl])
        }
    }, [locationData])

    useEffect(() => {
        document.body.classList.remove('stopScrolling')

        onPageStart('FLIGHTS_HOME_NEW')

        // isContentAuthenticNew()
        setUtmCookieOnHomePage()
        // setTimeout(() => {
        //     if (isCFWEnabled && isEmpty(wpUserStatus)) {
        //         dispatch(fetchWpUserStatus())
        //     }
        // }, 2000)

        setTimeout(() => {
            if (path(['androidData', 'app-agent'], window) || path(['iosData', 'app-agent'], window)) {
                sendBranchDetails('flights_home')
            }
        }, 5000)
    }, [])

    useEffect(() => {
        if (travellers && travellerEventtrigger) {
            Analytics.event('Air_UI_Action', {
                action_name: 'adults/children/infants updated',
                action_value: `${getFlightsTravellersText(travellers)}`
            })
            setTravellerEventTrigger(false)
        }
    }, [travellers, travellerEventtrigger])

    const isExactAirport = useMemo(() => {
        if (renderMevcG2NBA) {
            try {
                let exactSrc = renderMevcG2NBA
                let exactDestination = renderMevcG2NBA
                if (!isEmpty(locationData)) {
                    exactSrc = locationData.origin?.airportName !== 'All airports'
                    exactDestination = locationData.destination?.airportName !== 'All airports'
                }
                if (origin.code !== undefined && destination.code !== undefined) {
                    exactSrc = !origin?.airport?.includes('All airports')
                    exactDestination = !destination?.airport?.includes('All airports')
                }
                if (exactSrc && exactDestination) {
                    return true
                } else {
                    return false
                }
            } catch (err) {
                console.log(err)
            }
        }
        return false
    }, [locationData, origin, destination, renderMevcG2NBA])

    useEffect(() => {
        const obj = {}
        const { query } = props
        const { isCfw } = query
        const { adults, children, infants } = query.travellers
        const travellersCount = adults + children + infants
        const { adults: Adt, children: Chd, infants: Inf } = travellers
        const stateTravellersCount = Adt + Chd + Inf
        if (state.source !== query.source) {
            obj.origin = {
                code: query.source,
                airport: query.sourceHeader
            }
        }
        if (destination !== query.destination) {
            obj.destination = {
                code: query.destination,
                airport: query.destinationHeader
            }
        }
        if (state.departDate !== query.departDate) {
            obj.departOn =
                !query.departDate || (query.departDate && new Date(_getDate(query.departDate)) < new Date())
                    ? new Date()
                    : _getDate(query.departDate)

            setDepartOn(obj.departOn)
        }
        if (state.sft !== query.sft) {
            obj.splFareType = query.sft || 'rf'
        }
        if (state.returnDate !== query.returnDate) {
            obj.returnOn =
                !query.returnDate || (query.returnDate && new Date(_getDate(query.returnDate)) < new Date())
                    ? new Date()
                    : _getDate(query.returnDate)
            if (obj.returnOn < obj.departOn) {
                obj.returnOn = obj.departOn
            }
            setReturnOn(obj.returnOn)
        }
        if (travellersCount !== stateTravellersCount) {
            obj.travellers = query.travellers
            setTravellers({ ...obj.travellers })
        }
        if (isEmpty(flightClassVal) && !isEmpty(query.flightClass)) {
            obj.fClass = query.flightClass
        }
        if (obj.fClass !== query.flightClass) {
            obj.fClass = query.flightClass
            setFClass(obj.fClass)
        }

        if (!isEmpty(query.returnDate) && state.tripType == 'oneway') {
            obj.tripType = 'roundtrip'
        }

        if (!isEmpty(query.returnDate)) {
            const { returnDate } = query
            let returnOnInitial = returnDate && _getDate(returnDate)
            setReturnOn(returnOnInitial)
        }
        if (!isEmpty(query.tripType)) {
            obj.tripType = query.tripType
        }
        if (!isEmpty(query.departDate)) {
            const { departDate } = query
            const departOnInitial =
                !departDate || (departDate && new Date(_getDate(departDate)) < new Date())
                    ? new Date()
                    : _getDate(departDate)
            setDepartOn(departOnInitial)
        }

        setState({ ...state, ...obj })
        setOrigin({ ...obj.origin })
        setDestination({ ...obj.destination })
        if (!isEmpty(origin?.airport) && !isEmpty(destination?.airport)) {
            filterFlightClass(origin?.airport.split(','), destination?.airport.split(','))
        }
        obj.isCfw = isCfw
        setCfw(isCfw)
    }, [JSON.stringify(props.query)])

    useEffect(() => {
        if (state.showDateRangeCalendar === true) {
            Analytics.event('Air_UI_Action', {
                action_name: 'SRP_RT_Calendar_Opened',
                ...props.instrumentationPayload
            })
        }
    }, [state.showDateRangeCalendar])

    useEffect(() => {
        if (renderNewCalendarFlow) {
            let initialTripType, returnOnInitial
            initialTripType = tripType ? tripType : 'roundtrip'
            returnOnInitial = returnDate && _getDate(returnDate)
            setState(prev => ({ ...prev, tripType: initialTripType }))
            setReturnOn(() => returnOnInitial)
        }
    }, [renderNewCalendarFlow])

    useEffect(() => {
        if (origin.code && destination.code && renderNewCalendarFlow) {
            memoizedOneWayMonthsFetched.current = []
            memoizedRoundTripMonthsFetched.current = []
            memoizedOneWayCalendarData.current = {}
            memoizedRoundTripCalendarData.current = {}
            handleCalendarDataOnLoad()
        }
    }, [
        renderMevcG2NBA,
        origin,
        destination,
        renderNewCalendarFlow,
        mevcVariant,
        mevcG2VariantNBA,
        fClass,
        state.splFareType
    ])

    function handleGa(event, finalObj) {
        const { dataLayer = [] } = window || {}
        const finalData = {
            event: event,
            ...finalObj
        }
        pushGAData(finalData)
    }
    function filterFlightClass(originAirport, destinationAirport) {
        const isOriginDomestic = originAirport[1] && originAirport[1].indexOf('IN') !== -1
        const isDestinationDomestic = destinationAirport[1] && destinationAirport[1].indexOf('IN') !== -1
        const isDomestic = isOriginDomestic && isDestinationDomestic
        if (isDomestic) {
            const flightClass = flightClassVal.filter((fClass, index) => fClass.code !== 'First')

            if (fClass === 'First') {
                setFlightClassVal(flightClass)
                setFClass('Economy')
            } else {
                setFlightClassVal(flightClass)
            }
        } else {
            setFlightClassVal(FLIGHT_CLASS)
        }
    }
    function showSearchSheetHandler(obj) {
        setAirportType(obj.airportType)
        setShowSearchSheet(true)
    }

    function hideSheet(type) {
        setState(prev => {
            return {
                ...prev,
                value: '',
                showDateRangeCalendar: false,
                showSingleDatePicker: false
            }
        })
        setShowTravellerSheet(false)
        setAirportType('')
        setShowSearchSheet(false)

        if (type !== 'done' && renderNewCalendarFlow) {
            const ravenPayload = {
                action_name: 'hp_ow_calendar_close_click',
                action_type: 'click',
                page_name: 'flights_home',
                a_min_price: getFareCalenderCheapestFareValue(fareCalendar?.dayData) || 'N/A',
                a_departure_date: departOn ? formatDateStandardized(dayjs(departOn)?.format('DD/MM/YYYY')) : 'N/A',
                a_return_date: returnOn ? formatDateStandardized(dayjs(returnOn)?.format('DD/MM/YYYY')) : 'N/A',
                a_destination: destination?.code || 'N/A',
                a_origin: origin?.code || 'N/A',
                a_utm_source:
                    location?.search?.split('utm_source=')?.length > 1
                        ? location?.search?.split('utm_source=')?.pop()?.split('&')?.[0]
                        : 'organic',
                is_cfw_user: isCfw
            }
            ravenSDKTrigger('a_ui_action', ravenPayload)
        }
        initialTSCalendarOpen.current = null
    }

    function handleAirportSelection(item, selectedDataType) {
        const { code, airport } = item

        if (selectedDataType === 'data') {
            let history = window?.localStorage?.getItem('airportHistory')
            history = isEmpty(history) ? [] : JSON.parse(history)
            const filteredHistory = history.filter(value => {
                return value.code !== code
            })
            filteredHistory.unshift({ code, airport })
            window?.localStorage?.setItem('airportHistory', JSON.stringify(filteredHistory))
            dispatch(setAirportSearchHistory({ code, airport }))
        }

        setState({
            ...state,
            value: '',
            showDateRangeCalendar: false,
            showSingleDatePicker: false
        })
        if (airportType == 'Origin') {
            setOrigin({ code, airport })
            const originAirport = airport.split(',')
            const isOriginInternational = originAirport[1] && originAirport[1].indexOf('IN') === -1
            setIsIntlArray([isOriginInternational, isIntlArray[1]])
        } else {
            setDestination({ code, airport })
            const destinationAirport = airport.split(',')
            const isDestinationInternational = destinationAirport[1] && destinationAirport[1].indexOf('IN') === -1
            setIsIntlArray([isIntlArray[0], isDestinationInternational])
        }
        window.history.back()
        setShowTravellerSheet(false)
        setAirportType('')
        setShowSearchSheet(false)
    }

    function showOriginSearch() {
        showSearchSheetHandler({ airportType: 'Origin' })
    }

    function showDestinationSearch() {
        showSearchSheetHandler({ airportType: 'Destination' })
    }

    function getMonths({ date, format = 'MMMM-YYYY' }) {
        const selectedMonth = dayjs(date)
        const nextMonth = selectedMonth.month(selectedMonth.month() + 1).format('MMMM-YYYY')
        return { selectedMonth: selectedMonth.format(format), nextMonth }
    }

    function handleTripTypeChange(value) {
        let newReturnOn = returnOn
        if (value === 'roundtrip') {
            if (!renderNewCalendarFlow) {
                const expReturnDate = new Date(returnOn)
                const expDepartDate = new Date(departOn)
                if (
                    expReturnDate.getFullYear() === expDepartDate.getFullYear() &&
                    expReturnDate.getMonth() === expDepartDate.getMonth() &&
                    expReturnDate.getDate() === expDepartDate.getDate()
                ) {
                    newReturnOn = addDays(departOn, 1)
                } else if (returnOn < departOn) {
                    newReturnOn = departOn
                }
            } else {
                handleCalendarDataOnLoad({ currentTripType: 'roundtrip' })
            }
        } else if (value === 'oneway' && renderNewCalendarFlow) {
            const { selectedMonth } = getMonths({ date: departOn })
            const startDate = new Date(selectedMonth).getTime()
            fetchFareCalendarData({
                startDate,
                cb: data => {
                    if (data) {
                        memoizedOneWayCalendarData.current = data
                    }
                }
            })
        }

        setState({
            ...state,
            tripType: value
        })
        setReturnOn(newReturnOn)
        setCrossFlag(false)
        setClickFlag(false)
    }

    function showTravellerSelect() {
        setShowTravellerSheet(true)
    }

    function handleDepartClick() {
        setState(prev => ({ ...prev, showDateRangeCalendar: true, dateType: 'onward' }))
    }

    function getFareCalenderCheapestFareValue(fareCalendarData = {}) {
        return (
            !isEmpty(fareCalendarData) &&
            Object.values(fareCalendarData)?.find(fareList => fareList?.fareCategory === 'CHEAPEST')?.amount
        )
    }

    function calenderOpenAndCloseRavenPayloadCallback() {
        const pageLoadTime = parseInt(new Date().getTime() - initialTSCalendarOpen.current)
        if (mevcVariant === 'v2') {
            const ravenPayload = {
                action_name: state?.showDateRangeCalendar ? 'hp_rt_calendar_click' : 'hp_ow_calendar_click',
                action_type: 'click',
                page_name: 'flights_home',
                a_min_price: getFareCalenderCheapestFareValue(fareCalendar?.dayData) || 'N/A',
                personalised_callout:
                    !isEmpty(fareCalendar?.monthData) && Object.keys(fareCalendar?.monthData)?.length > 0
                        ? 'yes'
                        : 'no',
                a_departure_date: departOn ? formatDateStandardized(dayjs(departOn)?.format('DD/MM/YYYY')) : 'N/A',
                a_return_date: returnOn ? formatDateStandardized(dayjs(returnOn)?.format('DD/MM/YYYY')) : 'N/A',
                a_destination: destination?.code || 'N/A',
                a_origin: origin?.code || 'N/A',
                page_loadtime: (pageLoadTime > 0 && pageLoadTime) || 'N/A',
                a_utm_source:
                    location.search?.split('utm_source=')?.length > 1
                        ? location.search?.split('utm_source=')?.pop()?.split('&')?.[0]
                        : 'organic',
                is_cfw_user: isCfw
            }

            if (state?.showDateRangeCalendar) {
                // HP users clicked on the Return Tab to open calendar click event
                ravenSDKTrigger('a_ui_action', { ...ravenPayload, property: 'hp_return_tab' })
            } else {
                // HP users clicked on the Depature Tab to open calendar click event
                ravenSDKTrigger('a_ui_action', ravenPayload)
            }
        }
    }

    function handleReturnClick() {
        initialTSCalendarOpen.current = new Date().getTime()
        setState(prev => {
            if (renderNewCalendarFlow) {
                return {
                    ...prev,
                    showDateRangeCalendar: true,
                    dateType: 'return',
                    tripType: prev.tripType === 'oneway' ? 'roundtrip' : prev.tripType
                }
            }
            return { ...prev, showDateRangeCalendar: true, dateType: 'return' }
        })
        setFareCalendar(memoizedRoundTripCalendarData.current || {})
        Analytics.event('Air_UI_Action', {
            action_type: 'hp_return_on_clicked'
        })
    }
    function handleClickFlag() {
        initialTSCalendarOpen.current = new Date().getTime()
        setState(prev => ({
            ...prev,
            showDateRangeCalendar: true,
            dateType: 'return'
        }))
        setClickFlag(true)
        Analytics.event('Air_UI_Action', {
            action_type: 'hp_return_on_clicked'
        })
    }
    function handleSingleDepartClick() {
        initialTSCalendarOpen.current = new Date().getTime()
        setState(prev => ({ ...prev, showSingleDatePicker: true }))
        Analytics.event('Air_UI_Action', {
            action_name: 'SRP_OW_Calendar_Opened',
            ...props.instrumentationPayload
        })
    }

    // User Selected OW or RT date in calendar event
    // function handleDateChangeEvent(dateValues = [], dateType = '', amount = 0) {
    //     if (renderNewCalendarFlow) {
    //         const ravenPayload = {
    //             action_name: dateType === 'onward' ? 'hp_ow_date_selected' : 'hp_rt_date_selected',
    //             page_name: 'flights_home',
    //             a_min_price: amount > 0 ? amount : 'N/A',
    //             a_departure_date: !isEmpty(dateValues)
    //                 ? formatDateStandardized(dayjs(dateValues?.[0])?.format('DD/MM/YYYY'))
    //                 : 'N/A',
    //             a_return_date:
    //                 dateType === 'return' && !isEmpty(dateValues)
    //                     ? formatDateStandardized(dayjs(dateValues?.[1])?.format('DD/MM/YYYY'))
    //                     : 'N/A',
    //             a_destination: destination?.code || 'N/A',
    //             a_origin: origin?.code || 'N/A',
    //             a_utm_source:
    //                 location?.search?.split('utm_source=')?.length > 1
    //                     ? location?.search?.split('utm_source=')?.pop()?.split('&')?.[0]
    //                     : 'organic',
    //             is_cfw_user: isCfw
    //         }
    //         ravenSDKTrigger('a_ui_action', ravenPayload)
    //     }
    // }

    // function handleDateRangeSelectionV2([startDate, endDate]) {
    //     const { dateType } = state
    //     setState(pv => {
    //         return {
    //             ...pv,
    //             dateType: endDate ? (dateType === 'onward' ? 'return' : 'onward') : 'onward',
    //             tripType: endDate ? 'roundtrip' : 'oneway'
    //         }
    //     })
    //     let isDepartDateGreater = false
    //     if (!endDate) {
    //         isDepartDateGreater = new Date(startDate).getTime() > new Date(returnOn).getTime()
    //     }
    //     setCrossFlag(clickFlag ? true : false)
    //     setDepartOn(startDate)
    //     setReturnOn(isDepartDateGreater ? '' : endDate ? endDate : returnOn)
    //     Analytics.event('Air_UI_Action', {
    //         action_name: 'SRP_RT_Date_Selected',
    //         ...props.instrumentationPayload
    //     })
    //     if (renderNewCalendarFlow) {
    //         const ravenPayload = {
    //             action_name: endDate ? 'hp_rt_calendar_done_click' : 'hp_ow_calendar_done_click',
    //             action_type: 'click',
    //             page_name: 'flights_home',
    //             a_min_price: getFareCalenderCheapestFareValue(fareCalendar?.dayData) || 'N/A',
    //             a_departure_date: startDate ? formatDateStandardized(dayjs(startDate)?.format('DD/MM/YYYY')) : 'N/A',
    //             a_return_date: endDate ? formatDateStandardized(dayjs(endDate)?.format('DD/MM/YYYY')) : 'N/A',
    //             a_destination: destination?.code || 'N/A',
    //             a_origin: origin?.code || 'N/A',
    //             a_utm_source:
    //                 location?.search?.split('utm_source=')?.length > 1
    //                     ? location?.search?.split('utm_source=')?.pop()?.split('&')?.[0]
    //                     : 'organic',
    //             is_cfw_user: isCfw
    //         }
    //         ravenSDKTrigger('a_ui_action', ravenPayload)
    //     }
    //     initialTSCalendarOpen.current = null
    // }

    function handleDateRangeSelection([startDate, endDate]) {
        const { dateType } = state

        if (dateType === 'return') {
            setState({
                ...state,
                dateType: dateType === 'onward' ? 'return' : 'onward',
                tripType: 'roundtrip',
                value: '',

                showDateRangeCalendar: false,
                showSingleDatePicker: false
            })
            setCrossFlag(clickFlag ? true : false)
            setDepartOn(startDate)
            setShowTravellerSheet(false)
            setAirportType('')
            setShowSearchSheet(false)
            setReturnOn(endDate)
        } else {
            setState({
                ...state,
                dateType: dateType === 'onward' ? 'return' : 'onward',
                tripType: 'roundtrip'
            })
            setReturnOn(endDate)
            setCrossFlag(clickFlag ? true : false)
            setDepartOn(startDate)
        }
        Analytics.event('Air_UI_Action', {
            action_name: 'SRP_RT_Date_Selected',
            ...props.instrumentationPayload
        })
    }

    function handleDateTypeChange(dateType) {
        setState({ ...state, dateType })
    }
    function handleSplfareClick(value) {
        setState({ ...state, splFareType: value })
    }

    function handleSingleDateSelectionV2([startDate, endDate]) {
        setState(prev => {
            return {
                // showSingleDatePicker: false,
                ...prev,
                dateType: endDate ? 'return' : 'onward',
                tripType: endDate ? 'roundtrip' : 'oneway'
            }
        })
        setDepartOn(startDate)
        setReturnOn(returnOn < endDate ? endDate : returnOn)
        Analytics.event('Air_UI_Action', {
            action_name: 'SRP_OW_Date_Selected',
            ...props.instrumentationPayload
        })
    }

    function handleSingleDateSelection(date) {
        setState({
            ...state,
            showSingleDatePicker: false
        })
        Analytics.event('Air_UI_Action', {
            action_name: 'SRP_OW_Date_Selected',
            ...props.instrumentationPayload
        })
        setReturnOn(returnOn < date ? date : returnOn)
        setDepartOn(date)
    }

    function handleTravellersChange(travellersObj) {
        const travellersCt = { ...travellers, ...travellersObj }
        const { adults, infants } = travellersCt
        const infantsCount = adults < infants ? adults : infants
        travellersCt.infants = infantsCount

        setTravellerEventTrigger(true)
        setTravellers(travellersCt)
    }

    function handleClassChange(value) {
        setFClass(value)
        Analytics.event('Air_UI_Action', {
            action_name: 'class updated',
            travel_class: value
        })
    }

    function handleFlightSearch() {
        const { splFareType } = state
        let isInternational = true
        if (travellers.adults !== 0) {
            const currentTime = new Date().getTime()
            dispatch(setSearchCurrentTime(currentTime))
            let URL = ''
            let queryParams = {
                class: fClass,
                adults: travellers.adults,
                childs: travellers.children,
                infants: travellers.infants
            }
            // const originAirport = origin.airport.split(",")
            // const destinationAirport = destination.airport.split(",")
            // const isOriginInternational =
            //   originAirport[1] && originAirport[1].indexOf("IN") === -1
            // const isDestinationInternational =
            //   destinationAirport[1] && destinationAirport[1].indexOf("IN") === -1
            if (srpFlow) {
                if (props.abTest.next_exp_v2 == 'a' || props.abTest.next_exp_v2 == 'c') {
                    queryParams.srpFlow = 'old'
                }
                if (props.abTest.next_exp_v2 == 'b' || props.abTest.next_exp_v2 == 'd') {
                    queryParams.srpFlow = 'new'
                }
            }
            isInternational = isIntlArray[0] || isIntlArray[1]
            // if (
            //   origin.airport === origin.code &&
            //   destination.airport === destination.code
            // ) {
            //   isInternational = props.query.isIntl
            // }
            URL = getSearchURL(isInternational)
            const ravenPayload = {
                action_name: 'search_flight_srp',
                page_name: 'flights_srp',
                a_trip_type: isInternational ? 'international' : 'domestic',
                a_journey_type: state.tripType === 'oneway' ? 'ow' : 'rt',
                a_fare_type: returnFareName(splFareType),
                a_return_date: state.tripType === 'oneway' ? 'N/A' : convertDate(returnOn, '/'),
                a_dx: dayDiff(
                    new Date(getFormattedDate(convertDate(returnOn, '/') || '', 'MM/DD/YYYY', 'DD/MM/YYYY')),
                    new Date()
                ),
                a_adult_count: travellers.adults,
                a_origin: origin.code,
                a_child_count: travellers.children,
                a_infant_count: travellers.infants,
                a_departure_date: convertDate(departOn, '/'),
                a_destination: destination.code
            }
            isInternational
                ? ravenSDKTrigger(`a_${fromSRP ? 'srp' : 'hp'}_search_click_int`, { ...ravenPayload })
                : ravenSDKTrigger(`a_${fromSRP ? 'srp' : 'hp'}_search_click`, { ...ravenPayload })
            if (splFareType != 'rf' && !isInternational) {
                queryParams.sft = splFareType
            }
            queryParams = getSearchQueryParams({
                ...queryParams,
                from: origin.code,
                to: destination.code,
                depart_date: convertDate(departOn, '/'),
                return_date: state.tripType === 'roundtrip' ? convertDate(returnOn, '/') : undefined,
                from_header: origin.airport,
                to_header: destination.airport,
                intl: isInternational ? 'y' : 'n'
            })
            if (path(['androidData', 'app-agent'], window) || path(['iosData', 'app-agent'], window)) {
                const eventName = isInternational ? 'flights_srp_Int' : 'flights_srp'
                sendBranchDetails(eventName, isInternational)
            }
            if (amendmentData.isAmendment) {
                const searchAPIData = JSON.parse(localStorage.getItem('searchAPIData'))
                let modifiedSearchAPIData = {
                    ...searchAPIData,
                    depart_date: queryParams.depart_date,
                    return_date: queryParams.return_date ? queryParams.return_date : ''
                }
                localStorage.setItem('searchAPIData', JSON.stringify(modifiedSearchAPIData))
                browserHistory.push({
                    pathname: '/flights/amendments/search-results'
                })
            }

            // if (isInternational) {
            //     browserHistory.push({
            //       pathname: URL,
            //       query: queryParams,
            //     })
            //    // window.location.href = `${URL}?${toQueryString(queryParams)}`
            // } else {
            //     if (cfw && !isCfwFlow({ isLoginMandatory: true, variant: cfwVariant }) && !isLoginOpen) {
            //         setIsLoginOpen(true)
            //         return
            //     }
            //     window.location.href = `${URL}?${toQueryString(queryParams)}`
            // }
            if (setShowHomeToSRPLoader) {
                setShowHomeToSRPLoader(true)
            }
            if (typeof setInitialTS === 'function') {
                setInitialTS(new Date().getTime())
            }
            browserHistory.push({
                pathname: URL,
                query: queryParams
            })
            // useBt(() => {
            //     browserHistory.push({
            //         pathname: URL,
            //         query: queryParams
            //     })
            // })
        } else {
            showToastHandler('Invalid pax combination')
        }
    }

    const renderSearchListItem = item => (
        <ListItem className="h-48 flex flex-middle" key={item.code} enableRipple={true}>
            <CodeBadge size="regular" label={item.code} />{' '}
            <Truncate width={300} className="lh-copy fs-14 mr-10 ml-10">
                {item.airport}
            </Truncate>
        </ListItem>
    )

    function handleSwapAirports() {
        setDestination({ ...origin })
        setOrigin({ ...destination })
        setIsIntlArray([isIntlArray[1], isIntlArray[0]])
    }

    function showToastHandler(message) {
        dispatch(
            showToast({
                message: message
            })
        )
    }
    function getEmail() {
        return (getCookie('userid') || '').split('|')[0]
    }

    function showOfferDetails() {
        const isLoggedIn = getEmail() || 'no'

        Analytics.event('Air_UI_Action', {
            ...getClevertapBasicProperties({ isLoggedIn }),
            action_type: 'FlashSale_Message',
            current_page: 'cleartrip home viewed'
        })

        setState({ ...state, showOfferDetails: true })
    }
    function hideOfferDetails() {
        setState({ ...state, showOfferDetails: false })
    }
    function handleFormatMonth(startDate) {
        return dayjs(startDate).format('MMMM YYYY')
        // moment(startDate).format("MMMM YYYY");
    }

    function hideMsgToast(event) {
        if (event) {
            setState({ ...state, showMsgToast: false })
            event.stopPropagation()
        }
    }

    const pushCleverTrap = (e, name) => {
        Analytics.event('Air_UI_Action', {
            action_name: name,
            action_type: 'Link'
        })
    }

    const sendBranchDetails = (type, isInternational = false) => {
        try {
            const eventData = {
                key: type,
                domain: path(['location', 'hostname'], window)
            }
            let intl_prefix = isInternational ? '_Int' : ''
            if (type === `${'flights_srp'}${intl_prefix}`) {
                eventData['jt'] = state.tripType === 'roundtrip' ? 'RT' : 'OW'
                eventData['org'] = origin.code
                eventData['dest'] = destination.code
                eventData['date_depart'] = convertDateForTracking(departOn)
                eventData['adt'] = travellers.adults.toString()
                eventData['chd'] = travellers.children.toString()
                eventData['inf'] = travellers.infants.toString()
                eventData['dom_intl'] = isInternational ? 'I' : 'D'
                eventData['fare_class'] = getClassNameForTracking(fClass)
                if (state.tripType === 'roundtrip') {
                    eventData['date_return'] = convertDateForTracking(returnOn)
                }
            }
            sendBranchEvent(eventData)
        } catch (err) {
            console.log('event details error :', err)
        }
    }
    const { dateType } = state
    const { adults, children, infants } = travellers
    const travellerGroup1 = { adults, children }
    const travellerGroup2 = { infants }

    const totalValueGroup1 = Object.keys(travellerGroup1).reduce((sum, key) => sum + travellerGroup1[key], 0)
    const totalValueGroup2 = Object.keys(travellerGroup2).reduce((sum, key) => sum + travellerGroup2[key], 0)
    const PLACE_HOLDER = 'Search for city name or airport code'
    const META_DATA = {
        product: 'flights',
        type: 'airport',
        cityType: '',
        popularHeader: 'Popular Cities',
        recentSearchesHeader: 'Recent Searches'
    }
    META_DATA.cityType = airportType.toLowerCase()
    let departDateForCalender = departOn
        ? new Date(departOn.getFullYear(), departOn.getMonth(), departOn.getDate())
        : undefined

    if (isNaN(departDateForCalender)) {
        departDateForCalender = new Date()
    }
    let returnDateForCalender = returnOn
        ? new Date(returnOn.getFullYear(), returnOn.getMonth(), returnOn.getDate())
        : undefined
    if (state.tripType !== 'roundtrip' && !renderNewCalendarFlow) {
        returnDateForCalender = departDateForCalender
    }

    const getStartDate = date => {
        if (!date) {
            return
        }
        let startDate
        let currentMonth = dayjs(date).format('MM/1/YYYY')
        if (currentMonth) {
            startDate = new Date(currentMonth).getTime()
        }
        return startDate
    }

    const handleCalendarDataOnLoad = async ({ currentTripType = state.tripType } = {}) => {
        let departDate
        let startDate
        if (currentTripType === 'roundtrip') {
            departDate = departOn.getTime()
            if (returnOn) {
                startDate = getStartDate(returnOn)
            } else {
                departDate = ''
                startDate = getStartDate(departOn)
            }
        } else {
            startDate = getStartDate(departOn)
        }
        await fetchFareCalendarData({
            cb: data => {
                if (data) {
                    if (departDate) {
                        memoizedRoundTripCalendarData.current = data
                    } else {
                        memoizedOneWayCalendarData.current = data
                    }
                }
            },
            departDate,
            startDate
        })
    }

    const fetchFareCalendarData = async ({ departDate = '', cb = () => {}, startDate }) => {
        let currentDate = new Date()
        currentDate.setHours(0, 0, 0, 0)
        currentDate = currentDate.getTime()
        let shouldCallApi = true
        const { code: originCode = '' } = origin
        const { code: destinationCode = '' } = destination
        if (!originCode || !destinationCode) {
            return
        }
        const { splFareType } = state
        const isWPUser = false
        const fareCalendarPayload = {
            fareCalendarType: 'CHEAPEST',
            departDetails: {
                from: originCode,
                to: destinationCode,
                startDate: currentDate
            },
            paxDetails: {
                adult: 1,
                child: 0,
                infant: 0
            },
            fareDetails: {
                domain: 'IN',
                cabinType: fareDetails.cabinType[fClass] || 'ECONOMY',
                fareType: isCFWEnabled && isWPUser ? 'CORPORATE' : 'RETAIL',
                fareGroup: fareDetails.fareGroup[splFareType] || 'REGULAR',
                partnerId: '10000000'
            },
            numberOfDays: 61,
            journeyType: 'ONE_WAY'
        }
        if (renderMevcG2NBA && isExactAirport) {
            fareCalendarPayload.fareCalendarType = 'CHEAPEST_EXACT'
        }
        if (departDate) {
            fareCalendarPayload.departDate = departDate
            fareCalendarPayload.journeyType = 'ROUND_TRIP'
            if (memoizedDepartDate.current !== departDate) {
                memoizedDepartDate.current = departDate
                memoizedRoundTripCalendarData.current = {}
                memoizedRoundTripMonthsFetched.current = []
            } else {
                const { selectedMonth } = getMonths({ date: startDate })
                if (memoizedRoundTripMonthsFetched.current?.includes(selectedMonth)) {
                    shouldCallApi = false
                }
            }
        }
        if (startDate) {
            if (fareCalendarPayload.departDetails.startDate <= startDate) {
                fareCalendarPayload.departDetails.startDate = startDate
            }
            if (!departDate) {
                const { selectedMonth } = getMonths({ date: startDate })
                if (memoizedOneWayMonthsFetched.current?.includes(selectedMonth)) {
                    shouldCallApi = false
                }
            }
        }
        const startingDay = fareCalendarPayload.departDetails.startDate
        const remainingDays = dayjs(startingDay).daysInMonth() - dayjs(startingDay).date()
        const nextMonthDays = dayjs(startingDay)
            .add(remainingDays + 1, 'day')
            .daysInMonth()
        const numberOfDays = remainingDays + nextMonthDays + 1
        fareCalendarPayload.numberOfDays = numberOfDays
        if (previousCalendarPayload.current?.journeyType) {
            const {
                departDetails: { from: prevFrom = '', to: prevTo = '', startDate: prevStartDate = 0 },
                fareDetails: {
                    cabinType: prevCabinType = '',
                    fareType: prevFareType = '',
                    fareGroup: prevFareGroup = ''
                },
                journeyType = '',
                departDate: prevDepartDate
            } = previousCalendarPayload.current || {}
            const {
                departDetails: { from: currentFrom = '', to: currentTo = '', startDate: currentStartDate = 0 },
                fareDetails: { cabinType: currCabinType, fareType: currFareType, fareGroup: currFareGroup },
                journeyType: currJourneyType,
                departDate: currDepartDate
            } = fareCalendarPayload
            if (
                prevFrom !== currentFrom ||
                prevTo !== currentTo ||
                `${prevStartDate}` !== `${currentStartDate}` ||
                prevCabinType !== currCabinType ||
                prevFareType !== currFareType ||
                prevFareGroup !== currFareGroup ||
                journeyType !== currJourneyType ||
                prevDepartDate !== currDepartDate
            ) {
                previousCalendarPayload.current = fareCalendarPayload
            } else {
                shouldCallApi = false
            }
        } else {
            previousCalendarPayload.current = fareCalendarPayload
        }
        try {
            let currentData = departDate
                ? { ...memoizedRoundTripCalendarData.current }
                : { ...memoizedOneWayCalendarData.current }
            if (shouldCallApi) {
                const calendarResponse = await fareCalendarData(fareCalendarPayload)
                if (calendarResponse?.data?.errors?.length <= 0) {
                    if (departDate) {
                        const { selectedMonth, nextMonth } = getMonths({ date: startDate })
                        if (memoizedRoundTripMonthsFetched.current) {
                            memoizedRoundTripMonthsFetched.current.push(selectedMonth)
                            memoizedRoundTripMonthsFetched.current.push(nextMonth)
                        } else {
                            memoizedRoundTripMonthsFetched.current = [selectedMonth, nextMonth]
                        }
                    } else {
                        const { selectedMonth, nextMonth } = getMonths({ date: startDate })
                        if (memoizedOneWayMonthsFetched.current) {
                            memoizedOneWayMonthsFetched.current.push(selectedMonth)
                            memoizedOneWayMonthsFetched.current.push(nextMonth)
                        } else {
                            memoizedOneWayMonthsFetched.current = [selectedMonth, nextMonth]
                        }
                    }
                    if (currentData.dayData) {
                        currentData.dayData = { ...currentData.dayData, ...calendarResponse?.data.dayData }
                    } else {
                        currentData.dayData = { ...calendarResponse?.data.dayData }
                    }
                    if (currentData.monthData) {
                        currentData.monthData = { ...currentData.monthData, ...calendarResponse?.data.monthData }
                    } else {
                        currentData.monthData = { ...calendarResponse?.data.monthData }
                    }
                }
            }
            setFareCalendar(currentData)
            cb(currentData)
        } catch (e) {
            cb()
        }
    }

    const borderAndShadow = {
        border: 'solid 1px rgba(26, 26, 26, 0.1)',
        boxShadow: 'rgba(26, 26, 26, 0.1) 0px 0px 24px',
        borderRadius: 12,
        margin: 12,
        paddingBottom: 24,
        paddingTop: 24
    }

    return (
        <div className="mt-8">
            <div className={`px-4`} style={borderAndShadow}>
                <FlightSearchComponentNew
                    showOriginSearch={showOriginSearch}
                    showDestinationSearch={showDestinationSearch}
                    travellers={travellers}
                    showTravellerSelect={showTravellerSelect}
                    origin={origin}
                    destination={destination}
                    depart={departOn || null}
                    returnOn={returnOn || null}
                    class={fClass}
                    handleClassChange={handleClassChange}
                    handleFlightSearch={handleFlightSearch}
                    swapAirports={handleSwapAirports}
                    tripType={state.tripType}
                    handleTripTypeChange={handleTripTypeChange}
                    handleSingleDateSelection={
                        renderNewCalendarFlow ? handleSingleDateSelectionV2 : handleSingleDateSelection
                    }
                    handleDateRangeSelection={handleDateRangeSelection}
                    handleDepartClick={handleDepartClick}
                    handleReturnClick={handleReturnClick}
                    handleValidationError={showToastHandler}
                    handleSingleDepartClick={handleSingleDepartClick}
                    pushClevertap={pushCleverTrap}
                    queryParams={props.queryParams}
                    closeFromSRP={props.closeFromSRP}
                    fromSRP={props.fromSRP}
                    crossFlag={crossFlag}
                    handleClickFlag={handleClickFlag}
                    activeFare={state.splFareType}
                    handleSplfareClick={handleSplfareClick}
                    instrumentationPayload={props.instrumentationPayload}
                    handleGa={handleGa}
                    isIntlArray={isIntlArray}
                    renderNewCalendarFlow={renderNewCalendarFlow}
                    cfw={cfw}
                    setCfw={setCfw}
                    isLoginOpen={isLoginOpen}
                    setIsLoginOpen={setIsLoginOpen}
                    sft={sft}
                    isCfw={isCfw}
                    title={title}
                />
            </div>
            <AirportSearchContainer
                isOpen={showSearchSheet}
                title={`Select ${airportType} Airport`}
                onClose={hideSheet}
                meta={META_DATA}
                placeholder={PLACE_HOLDER}
                value={state.value}
                onSelect={handleAirportSelection}
                renderItem={renderSearchListItem}
                popularData={getPopularCities()}
                recentData={airports}
                enableGeoLocation={false}
            />
            <Sheet
                isOpen={!renderNewCalendarFlow && state.showDateRangeCalendar}
                title={'Select Date'}
                onClose={hideSheet}
                hashUrl="datePickerModal"
            >
                <Tabs value={state.dateType} onChange={handleDateTypeChange}>
                    <Tab value="onward" label={'Onward'} />
                    <Tab value="return" label={'Return'} />
                </Tabs>
                <Calendar
                    selectRange={true}
                    minDate={today}
                    value={[departDateForCalender, returnDateForCalender]}
                    numberOfMonths={13}
                    onChange={handleDateRangeSelection}
                    activeStartDate={thisMonth}
                    selectionState={dateType === 'onward' ? 'start' : 'end'}
                    formatMonth={handleFormatMonth}
                    weekendDays={[5, 6]}
                    weekStartDay={6}
                    isRTL={false}
                />
            </Sheet>
            <Sheet
                isOpen={!renderNewCalendarFlow && state.showSingleDatePicker}
                title={'Select Date'}
                onClose={hideSheet}
                hashUrl="singleCalendarModal"
            >
                <Calendar
                    selectRange={false}
                    minDate={today}
                    value={departDateForCalender}
                    numberOfMonths={13}
                    onChange={handleSingleDateSelection}
                    activeStartDate={thisMonth}
                    formatMonth={handleFormatMonth}
                    weekendDays={[5, 6]}
                    weekStartDay={6}
                    isRTL={false}
                />
            </Sheet>

            <BottomSheet
                open={showTravellerSheet}
                onClose={hideSheet}
                hashUrl="travellersModal"
                noPadding
                overlayWrapperClass="homePage"
            >
                <div
                    className="Selector flex flex-column flex-between"
                    style={{ background: '#fff', height: 'calc(100vh - 54px)' }}
                >
                    <div>
                        <div>
                            <Truncate className="ml-16 mb-1 lh-28 fs-20 fw-510 pt-8" width="80%">
                                Select travellers
                            </Truncate>
                        </div>
                        <CounterNew
                            value={travellerGroup1}
                            maxValue={MAX_ADULT_SELECTION}
                            onChange={handleTravellersChange}
                            totalValue={totalValueGroup1}
                        >
                            <CountNew id="adults" minValue={MIN_ADULT_SELECTION} showToast={showToastHandler}>
                                {count => (
                                    <>
                                        <p>{pluralize(count, 'Adult')} </p>
                                        <p>{'12+ Years'}</p>
                                    </>
                                )}
                            </CountNew>
                            <CountNew id="children" activeFare={state.splFareType} showToast={showToastHandler}>
                                {count => (
                                    <>
                                        <p>{pluralize(count, 'Child', 'ren')} </p>
                                        <p>{'2 - 12 Years'}</p>
                                    </>
                                )}
                            </CountNew>
                        </CounterNew>
                        <CounterNew
                            value={travellerGroup2}
                            maxValue={travellers.adults}
                            onChange={handleTravellersChange}
                            className=""
                            totalValue={totalValueGroup2}
                            activeFare={state.splFareType}
                        >
                            <CountNew id="infants" activeFare={state.splFareType} showToast={showToastHandler}>
                                {count => (
                                    <>
                                        <p>{pluralize(count, 'Infant')} </p>
                                        <p>{'Below 2 Years'}</p>
                                    </>
                                )}
                            </CountNew>
                        </CounterNew>

                        <div className="mt-5">
                            <div>
                                <Truncate className="ml-16 mb-5 lh-28 fs-20 fw-510" width="80%">
                                    Select class
                                </Truncate>
                            </div>
                            <div>
                                {flightClassVal.map(fClassObj => (
                                    <div
                                        key={fClassObj.value}
                                        onClick={() => handleClassChange(fClassObj.value)}
                                        style={{ columnGap: '16px', paddingLeft: '16px' }}
                                        className="mb-24 flex flex-middle w-100p"
                                    >
                                        <input type="radio" name="class_select" checked={fClass === fClassObj.value} />
                                        <div className="fs-14">{fClassObj.value}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <Sticky position="bottom">
                        <div className="ta-c pb-30 pt-24 pl-16 pr-16 bg-white">
                            <Button type="secondary" size="full" onClick={() => window.history.back()}>
                                {'Apply'}
                            </Button>
                        </div>
                    </Sticky>
                </div>
            </BottomSheet>
            {/* <ToastContainer/> */}
        </div>
    )
}

Search.defaultProps = {
    from: '',
    to: '',
    queryParams: '',
    query: {
        source: '',
        sourceHeader: 'Origin',
        destination: '',
        destinationHeader: 'Destination',
        departDate: startOfToday().getTime(),
        returnDate: addDays(startOfToday(), 1).getTime(),
        travellers: {
            adults: 1,
            children: 0,
            infants: 0
        },
        flightClassVal: 'Economy'
    }
}

export default Search
