import React, { Component } from "react";
import PropTypes from "prop-types";
import className from "classnames";

import Ripple from "@lib/Animate/Ripple/";

const valueBlock = (value, valueClassList, showEmptyValue) => {
  if (!value && showEmptyValue) {
    return <p className="fs-70 lh-none h-48">...</p>;
  }
  return (
    <p style={{ maxWidth: "132px" }} className={valueClassList}>
      {value}
    </p>
  );
};

class InputBlock extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.value !== nextProps.value ||
      this.props.hasError !== nextProps.hasError ||
      this.props.label !== nextProps.label ||
      this.props.style !== nextProps.style
    );
  }

  render() {
    const labelClassList = className(
      "TouchAndGo__label",
      this.props.labelClass,
      {
        "c-red": this.props.hasError === true,
        "c-black-40": !this.props.hasError,
        "c-black-80": this.props.hasError === "black",
      }
    );
    const fieldClassList = className(
      "TouchAndGo__field",
      this.props.fieldClass
    );
    const valueClassList = className(
      "TouchAndGo__value",
      this.props.valueClass,
      { "t-truncate": !!this.props.originDestinationFlag }
    );
    return (
      <Ripple enableRipple={this.props.enableRipple}>
        {(rippleProps, RippleContent) => {
          return (
            <div
              role="button"
              className={fieldClassList}
              onClick={this.props.onClick}
              style={this.props.style}
              {...rippleProps}
            >
              <p className={labelClassList}>{this.props.label}</p>
              <div
                className={className({
                  "flex flex-middle flex-right":
                    this.props.returnFromOw ||
                    this.props.originDestinationFlag === "destination",
                })}
              >
                {valueBlock(
                  this.props.value,
                  valueClassList,
                  this.props.showEmptyValue
                )}
                {!!this.props.returnFromOw && (
                  <span
                    onClick={(event) => {
                      event.stopPropagation();
                      this.props.returnBackToOneWay();
                    }}
                    className="ml-12 mr-4"
                  >
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 1L5 5M5 5L1 9M5 5L1 1M5 5L9 9"
                        stroke="#1A1A1A"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                )}
              </div>
              {RippleContent}
            </div>
          );
        }}
      </Ripple>
    );
  }
}

InputBlock.propTypes = {
  hasError: PropTypes.bool,
  fieldClass: PropTypes.string,
  labelClass: PropTypes.string,
  valueClass: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
  showEmptyValue: PropTypes.bool,
  enableRipple: PropTypes.bool,
};

InputBlock.defaultProps = {
  hasError: false,
  fieldClass: "",
  labelClass: "",
  valueClass: "",
  label: "",
  value: "",
  style: {},
  onClick: (event) => {},
  showEmptyValue: false,
  enableRipple: true,
};

export default InputBlock;
