import React from "react";
import classNames from "classnames";
import analytics from "@ui/utils/Analytics";
import { isFkEnv } from "@root/configs/Whitelabel";
import { ravenSDKTrigger } from "@utils/RavenSDKHelper";
import { getItineraryData } from "@redux/manager/itineraryManager";
import { useAppSelector } from "@redux/hooks";

const SegmentTab = ({
  label,
  value,
  className,
  selected = false,
  onClick = () => {},
  fromHP=false,
  renderNewCalendarFlow=false
}) => {
  let itineraryData = useAppSelector(getItineraryData)
  const isCfw = itineraryData?.SEARCH_DETAILS?.itineraryTags?.includes('CFW') || false
  const handleTouchTap = (event) => {
    if (onClick) {
      analytics.event("Air_UI_Action", {
        action_name: value === "roundtrip" ? "hp_radio_rt" : "hp_radio_ow",
      });
      onClick(value, event);
    }
    if(fromHP && renderNewCalendarFlow){
      const revanPayload = {
        action_name: value === "roundtrip" ? "hp_radio_rt_click" : "hp_radio_ow_click",
        action_type: "click",
        page_name: "flights_home",
        is_cfw_user: isCfw
      }
      ravenSDKTrigger('a_ui_action', revanPayload)
    }
  };

  const classList = classNames(
    `SegmentTabs__${isFkEnv ? 'fk-' : ''}item ${selected && "is-active"}`,
    className
  );
  return (
    <div
      className={classList}
      value={value}
      selected={selected}
      onClick={handleTouchTap}
    >
      <p>{label}</p>
    </div>
  );
};

export default SegmentTab;
